<template>
  <b-container>
    <Heading type="h1" :title="trans('global.users')" />
    <b-row>
      <b-col>
        <div class="alert alert-warning">
          <i class="fas fa-exclamation-triangle padding-right-6"></i> {{ trans('users.no_users') }}
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
  import Heading from '../../components/Core/Heading'

  export default {
    name: 'AllProjects',
    components: {
      Heading
    },
    beforeMount () {
      this.updateBreadcrumb([{ text: this.trans('global.users'), to: '/users' }, { text: this.trans('navigation.all_users'), to: '#' }])
    }
  }
</script>
